import SButton from '../../../../../../../components/ui/s-button'
import STitle from '../../../../../../../components/ui/s-title'
import { Auth } from '../../../../../../../plugins/firebase'

export default {
  name: 'ArchiveMode',

  components: {
    SButton,
    STitle,
  },

  methods: {
    async submit() {
      if (!Auth.currentUser || Auth.currentUser.isAnonymous === true) {
        this.$emit('showLoginForm')
      } else {
        this.$emit('submit', 'DRAFT')
      }
    },
  },
}
